import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

import store from '../store/auth';
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/main/IndexView.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/main/LoginView.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/account/DashboardView.vue')
    },
    {
        path: '/driver-dashboard',
        name: 'driver_dashboard',
        component: () => import('../views/account/DriverDashboardView.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/account/ProfileView.vue')
    }, {
        path: '/sponsors',
        name: 'sponsor',
        component: () => import('../views/sponsor/SponsorView.vue')
    },
    {
        path: '/sponsors/add',
        name: 'addSponsor',
        component: () => import('../views/sponsor/AddSponsorView.vue')
    },
    {
        path: '/sponsors/edit',
        name: 'editSponsor',
        component: () => import('../views/sponsor/UpdateSponsorView.vue')
    },
    {
        path: '/menu',
        name: 'menu',
        component: () => import('../views/menu/MenuView.vue')
    },
    {
        path: '/menu/upload',
        name: 'menu_upload',
        component: () => import('../views/menu/UploadMenu.vue')
    },
    // {
    //     path: '/menu/copy',
    //     name: 'menu.copy',
    //     component: () => import('../views/menu/CopyMenu.vue'),
    // },
    {
        path: '/menu/create/:date/:meal_type/:site_id/:sponsor',
        name: 'menu.create',
        component: () => import('../views/menu/CreateMenu.vue'),
        props: true,
    },
    {
        path: '/delivery_slips',
        name: 'delivery_slips',
        component: () => import('@/views/delivery_slips/DeliverySlipsView.vue')
    },
    {
        path: '/delivery_slips/create',
        name: 'delivery_slips.create',
        component: () => import('@/views/delivery_slips/CreateDeliverySlipView.vue')
    },
    {
        path: '/delivery_slips/create_multiple',
        name: 'delivery_slips.create_multiple',
        component: () => import('@/views/delivery_slips/CreateMultipleDeliverySlipView.vue')
    },
    {
        path: '/driver/delivery_slips',
        name: 'driver_delivery_slips',
        component: () => import('@/views/delivery_slips/DriverDeliverySlipsView.vue')
    },
    {
        path: '/delivery_slips/generate',
        name: 'delivery_slips.generate',
        component: () => import('@/views/delivery_slips/GenerateDeliverySlipsView.vue')
    },
    {
        path: '/delivery_slip/detail/:id/:sponsor_id',
        name: 'delivery_slip_detail',
        component: () => import('@/views/delivery_slips/ShowDeliverySlipView.vue'),
        props: true,
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/users/UsersView.vue')
    }, {
        path: '/drivers',
        name: 'drivers',
        component: () => import('@/views/driver/DriversView.vue')
    },
    {
        path: '/contracts',
        name: 'contracts',
        component: () => import('@/views/contracts/ContractList.vue')
    },
    {
        path: '/contracts/add',
        name: 'contracts.add',
        component: () => import('@/views/contracts/CreateContract.vue')
    },
    {
        path: '/contracts/edit/:id',
        name: 'contracts.edit',
        component: () => import('@/views/contracts/EditContract.vue'),
        props: true,
    },
    {
        path: '/drivers/add',
        name: 'drivers.add',
        component: () => import('@/views/driver/AddDriverView.vue')
    },
    {
        path: '/drivers/edit/:id',
        name: 'drivers.edit',
        component: () => import('@/views/driver/UpdateDriverView.vue'),
        props: true,
    },
    {
        path: '/drivers/signature/',
        name: 'drivers.signature',
        component: () => import('@/views/driver/SignatureView.vue'),

    },

    {
        path: '/food-server',
        name: 'food_server',
        component: () => import('@/views/food_server/FoodServerView.vue')
    },

    {
        path: '/food-server/add',
        name: 'food_server.add',
        component: () => import('@/views/food_server/AddFoodServerView.vue')
    },
    {
        path: '/food-server/edit/:id',
        name: 'food_server.edit',
        component: () => import('@/views/food_server/UpdateFoodServerView.vue'),
        props: true,
    },
    {
        path: '/brand',
        name: 'brand',
        component: () => import('@/views/brand/BrandView.vue'),

    },
    {
        path: '/brand/create',
        name: 'brand.create',
        component: () => import('@/views/brand/AddBrandView.vue'),

    },
    {
        path: '/brand/edit/:id',
        name: 'brand.edit',
        component: () => import('@/views/brand/EditBrandView.vue'),
        props: true,

    },
    {
        path: '/items',
        name: 'items',
        component: () => import('@/views/item/ItemView.vue'),

    },
    {
        path: '/items/create',
        name: 'items.create',
        component: () => import('@/views/item/AddItemView.vue'),

    },
    {
        path: '/items/edit/:id',
        name: 'items.edit',
        component: () => import('@/views/item/EditItemView.vue'),
        props: true,

    },
    {
        path: '/meal_contribution',
        name: 'meal_contribution',
        component: () => import('@/views/meal_contribution/MealContributionView.vue'),

    },
    {
        path: '/meal_contribution/create',
        name: 'meal_contribution.create',
        component: () => import('@/views/meal_contribution/AddMealContributionView.vue'),

    },
    {
        path: '/meal_contribution/edit/:id',
        name: 'meal_contribution.edit',
        component: () => import('@/views/meal_contribution/EditMealContributionView.vue'),
        props: true,

    },
    {
        path: '/meal-count',
        name: 'meal_count',
        component: () => import('@/views/meal_count/MealCountView.vue')
    },
    {
        path: '/chefs',
        name: 'chefs',
        component: () => import('@/views/chefs/ChefsView.vue')
    },
    {
        path: '/chefs/add',
        name: 'chefs.add',
        component: () => import('@/views/chefs/AddChefView.vue')
    },
    {
        path: '/chefs/edit/:id',
        name: 'chefs.edit',
        component: () => import('@/views/chefs/UpdateChefView.vue'),
        props: true,
    },
    {
        path: '/chefs/show/:id',
        name: 'chefs.show',
        component: () => import('@/views/chefs/ShowChefView.vue'),
        props: true,
    },
    {
        path: '/sponsor_representative',
        name: 'sponsor_representative',
        component: () => import('@/views/sponsor_representative/SponsorRepresentativeView.vue')
    },
    {
        path: '/sponsor_representative/add',
        name: 'sponsor_representative.add',
        component: () => import('@/views/sponsor_representative/AddSponsorRepresentativeView.vue')
    },
    {
        path: '/sponsor_representative/edit/:id',
        name: 'sponsor_representative.edit',
        component: () => import('@/views/sponsor_representative/UpdateSponsorRepresentativeView.vue'),
        props: true,
    },
    {
        path: '/sponsor_representative/signature/',
        name: 'sponsor_representative.signature',
        component: () => import('@/views/sponsor_representative/SignatureView.vue'),

    },
    {
        path: '/sponsor_representative/show/:id',
        name: 'sponsor_representative.show',
        component: () => import('@/views/chefs/ShowChefView.vue'),
        props: true,
    },
    {
        path: '/school-food-authority',
        name: 'sfa',
        component: () => import('@/views/school_food_authority/SchoolFoodAuthorityView.vue')
    },
    {
        path: '/school-food-authority/add',
        name: 'sfa.add',
        component: () => import('@/views/school_food_authority/AddSchoolFoodAuthorityView.vue')
    },
    {
        path: '/school-food-authority/edit/:id',
        name: 'sfa.edit',
        component: () => import('@/views/school_food_authority/UpdateSchoolFoodAuthorityView.vue'),
        props: true,
    },
    {
        path: '/community-sponsors',
        name: 'community_sponsors',
        component: () => import('@/views/community_sponsors/CommunitySponsorsView.vue')
    },
    {
        path: '/community-sponsors/add',
        name: 'community_sponsors.add',
        component: () => import('@/views/community_sponsors/AddCommunitySponsorsView.vue')
    },
    {
        path: '/community-sponsors/edit/:id',
        name: 'community_sponsors.edit',
        component: () => import('@/views/community_sponsors/UpdateCommunitySponsorsView.vue'),
        props: true,
    },
    {
        path: '/nutritionists',
        name: 'nutritionist',
        component: () => import('@/views/nutritionist/NutritionistView.vue')
    },
    {
        path: '/nutritionists/add',
        name: 'nutritionist.add',
        component: () => import('@/views/nutritionist/AddNutritionistView.vue')
    },
    {
        path: '/nutritionists/edit/:id',
        name: 'nutritionist.edit',
        component: () => import('@/views/nutritionist/UpdateNutritionistView.vue'),
        props: true,
    },
    {
        path: '/employers',
        name: 'employer',
        component: () => import('@/views/employer/EmployerView.vue')
    },
    {
        path: '/employers/add',
        name: 'employer.add',
        component: () => import('@/views/employer/AddEmployerView.vue')
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('@/views/invoice/InvoiceView.vue')
    },

    {
        path: '/employers/edit/:id',
        name: 'employer.edit',
        component: () => import('@/views/employer/UpdateEmployerView.vue'),
        props: true,
    },
    {
        path: '/food-production-records',
        name: 'food_production_record',
        component: () => import('@/views/food_production_record/FoodProductionRecordView.vue')
    },
    {
        path: '/food-production-records/create',
        name: 'food_production_record.create',
        component: () => import('@/views/food_production_record/CreateFoodProductionRecordView.vue')
    },
    {
        path: '/food-production-records/:id/show',
        name: 'food_production_record.show',
        component: () => import('@/views/food_production_record/ShowFoodProductionRecordView.vue'),
        props: true,
    },
    {
        path: '/food-production-records/:id/edit',
        name: 'food_production_record.edit',
        component: () => import('@/views/food_production_record/EditFoodProductionRecordView.vue'),
        props: true,
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/orders/OrdersView.vue'),
    },
// {
//         path: '/food-production-records/:id/show',
//         name: 'food_production_record.show',
//         component: () => import('@/views/food_production_record/ShowFoodProductionRecordView.vue'),
//         props: true,
//     },
// {
//         path: '/food-production-records/:id/show',
//         name: 'food_production_record.show',
//         component: () => import('@/views/food_production_record/ShowFoodProductionRecordView.vue'),
//         props: true,
//     },

    // {
    //     path: '/employers/add',
    //     name: 'employer.add',
    //     component: () => import('@/views/employer/AddEmployerView.vue')
    // },
    // {
    //     path: '/employers/edit/:id',
    //     name: 'employer.edit',
    //     component: () => import('@/views/employer/UpdateEmployerView.vue'),
    //     props: true,
    // },
    {
        path: '/permission-alert',
        name: 'permission',
        component: () => import('@/views/main/AuthorizeView.vue'),
        props: true,
    },
    {
        path: '/documents',
        name: 'documents',
        component: () => import('@/views/documents/DocumentView.vue')
    },


]

const router = new VueRouter({
    routes
})
//
router.beforeEach((to, from, next) => {


    let open = store.getters.openRoutes;
    let user = store.getters.user;

    let driverRoutes = store.getters.driverRoutes;

    let includes = open.includes(to.name);

    if (includes) {
        if (store.getters.authenticated) {

            if (to.name == 'login') {
                // if (user && user.role == 1) {
                next({'name': 'dashboard'});
                return;
                // }
                // next({'name': 'driver_dashboard'});
                //
                // return;
            }
        }
        next();
        return;
    }


    if (!store.getters.authenticated) {
        next({'name': 'login'})
    }
    if (user && user.role == 2) {

        if (driverRoutes.includes(to.name)) {
            next();
        } else {
            next({'name': 'permission'})
        }
        return;
    }
    next();
    return;
});


// if (!store.getters.authenticated) {
// next({'name': 'login'})
// }
//
//
// next();
// console.log('Called');

// next({'name': 'login'});
//     return;
// }
// if (includes && store.getters.authenticated) {
//
//     if (!from.name) {
//         next({'name': 'dashboard'});
//     }
// }

//
// alert('Dashboard');
// next({'name': 'login'});
// })
// ;

export default router
